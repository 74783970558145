import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Login from './login/Login';
import { useAuth } from './hooks';
import {
  AuthProvider,
  SnackbarProvider,
  LoaderProvider,
  ModalMapProvider,
  ModalImgProvider,
  ModalFormProvider,
} from './providers';
import Layout from './layout/layout';
import { Loader } from './components';
import { FormOnClose } from './utils/dataTypes';

export const MapComponent = React.lazy(
  () => import('./GoogleMaps/mapComponent'),
);
export const StartPage = React.lazy(() => import('./components/Start'));
export const NotFoundPage = React.lazy(
  () => import('./components/NotFoundPage'),
);
export const TrucksNearby = React.lazy(
  () => import('./trucksNearby/trucksNearby'),
);
export const CreateTruck = React.lazy(() => import('./trucks/createTruck'));
export const EditTruck = React.lazy(() => import('./trucks/editTruck'));
export const ViewTruck = React.lazy(() => import('./trucks/viewTruck'));
export const Trucks = React.lazy(() => import('./trucks/trucks'));
export const Loads = React.lazy(() => import('./loads/components/table/loads'));
export const CreateLoad = React.lazy(() => import('./loads/createLoad'));
export const EditLoad = React.lazy(() => import('./loads/editLoad'));
export const SetLoadsRateInfo = React.lazy(() => import('./loads/setRateInfo'));
export const ViewLoad = React.lazy(() => import('./loads/viewLoad'));
export const CreateOwner = React.lazy(() => import('./owners/createOwner'));
export const EditOwner = React.lazy(() => import('./owners/editOwner'));
export const ViewOwner = React.lazy(() => import('./owners/viewOwner'));
export const CreateOwnerDriver = React.lazy(
  () => import('./ownersDrivers/createOwnerDriver'),
);
export const EditOwnerDriver = React.lazy(
  () => import('./ownersDrivers/editOwnerDriver'),
);
export const ViewOwnerDriver = React.lazy(
  () => import('./ownersDrivers/viewOwnerDriver'),
);
export const Owners = React.lazy(() => import('./owners/owners'));
export const CreateCoordinator = React.lazy(
  () => import('./coordinators/createCoordinator'),
);
export const EditCoordinator = React.lazy(
  () => import('./coordinators/editCoordinator'),
);
export const ViewCoordinator = React.lazy(
  () => import('./coordinators/viewCoordinator'),
);
export const CreateCoordinatorDriver = React.lazy(
  () => import('./coordinatorsDrivers/createCoordinatorDriver'),
);
export const EditCoordinatorDriver = React.lazy(
  () => import('./coordinatorsDrivers/editCoordinatorDriver'),
);
export const ViewCoordinatorDriver = React.lazy(
  () => import('./coordinatorsDrivers/viewCoordinatorDriver'),
);
export const Coordinators = React.lazy(
  () => import('./coordinators/coordinators'),
);
export const CreateDriver = React.lazy(() => import('./drivers/createDriver'));
export const EditDriver = React.lazy(() => import('./drivers/editDriver'));
export const ViewDriver = React.lazy(() => import('./drivers/viewDriver'));
export const Drivers = React.lazy(() => import('./drivers/drivers'));
export const CreateLocation = React.lazy(
  () => import('./locations/createLocation'),
);
export const EditLocation = React.lazy(
  () => import('./locations/editLocation'),
);
export const ViewLocation = React.lazy(
  () => import('./locations/viewLocation'),
);
export const Locations = React.lazy(() => import('./locations/locations'));
export const CreateUser = React.lazy(() => import('./users/pages/createUser'));
export const EditUser = React.lazy(() => import('./users/pages/editUser'));
export const ViewUser = React.lazy(() => import('./users/pages/viewUser'));
export const Users = React.lazy(() => import('./users/pages/users'));
export const CreateCustomer = React.lazy(
  () => import('./customers/createCustomer'),
);
export const EditCustomer = React.lazy(
  () => import('./customers/editCustomer'),
);
export const ViewCustomer = React.lazy(
  () => import('./customers/viewCustomer'),
);
export const Customers = React.lazy(() => import('./customers/customers'));
export const CreateFacility = React.lazy(
  () => import('./facilities/createFacility'),
);
export const EditFacility = React.lazy(
  () => import('./facilities/editFacility'),
);
export const ViewFacility = React.lazy(
  () => import('./facilities/viewFacility'),
);
export const Facilities = React.lazy(() => import('./facilities/facilities'));
export const Pushs = React.lazy(() => import('./pushs/pushs'));
export const Emails = React.lazy(() => import('./emails/emails'));
export const GeneralMap = React.lazy(() => import('./GoogleMaps/map'));

const minutesToMs = (minutes: number): number => 1000 * 60 * minutes;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: minutesToMs(5),
      cacheTime: minutesToMs(10),
      refetchOnWindowFocus: true,
    },
  },
});

export default function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClose: FormOnClose = (eventType) => {
    if (eventType === 'Edit') {
      navigate(pathname.replace('view', 'edit'), { replace: true });
    } else {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        handleCloseWithoutHistory();
      }
    }
  };

  const handleCloseWithoutHistory = () => {
    const segments = pathname.split('/');

    if (segments.length > 2) {
      navigate(`/${segments[1]}`);
    } else {
      navigate('/');
    }
  };

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <LoaderProvider>
          <SnackbarProvider>
            <ModalFormProvider>
              <ModalMapProvider>
                <ModalImgProvider>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="*"
                      element={
                        <RequireAuth>
                          <Layout>
                            <React.Suspense fallback={<Loader visible />}>
                              <Routes>
                                <Route
                                  path="/trucks_nearby/create"
                                  element={
                                    <CreateTruck onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/trucks_nearby/edit/*"
                                  element={
                                    <EditTruck
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/trucks_nearby/view/*"
                                  element={
                                    <ViewTruck
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/trucks_nearby"
                                  element={<TrucksNearby />}
                                />
                                <Route
                                  path="/general_map"
                                  element={<GeneralMap />}
                                />
                                <Route
                                  path="/loads/create"
                                  element={<CreateLoad onClose={handleClose} />}
                                />
                                <Route
                                  path="/loads/edit/*"
                                  element={
                                    <EditLoad
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/loads/setRateInfo/*"
                                  element={
                                    <SetLoadsRateInfo
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/loads/view/*"
                                  element={
                                    <ViewLoad
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route path="/loads" element={<Loads />} />
                                <Route
                                  path="/trucks/create"
                                  element={
                                    <CreateTruck onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/trucks/edit/*"
                                  element={
                                    <EditTruck
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/trucks/view/*"
                                  element={
                                    <ViewTruck
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route path="/trucks" element={<Trucks />} />
                                <Route
                                  path="/owners/create"
                                  element={
                                    <CreateOwner onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/owners/edit/*"
                                  element={
                                    <EditOwner
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/owners/view/*"
                                  element={
                                    <ViewOwner
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/ownersDrivers/create"
                                  element={
                                    <CreateOwnerDriver onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/ownersDrivers/edit/*"
                                  element={
                                    <EditOwnerDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/ownersDrivers/view/*"
                                  element={
                                    <ViewOwnerDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route path="/owners" element={<Owners />} />
                                <Route
                                  path="/ownersDrivers"
                                  element={<Navigate to="/drivers" replace />}
                                />
                                <Route
                                  path="/coordinators/create"
                                  element={
                                    <CreateCoordinator onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/coordinators/edit/*"
                                  element={
                                    <EditCoordinator
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/coordinators/view/*"
                                  element={
                                    <ViewCoordinator
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/coordinatorsDrivers/create"
                                  element={
                                    <CreateCoordinatorDriver
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/coordinatorsDrivers/edit/*"
                                  element={
                                    <EditCoordinatorDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/coordinatorsDrivers/view/*"
                                  element={
                                    <ViewCoordinatorDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/coordinators"
                                  element={<Coordinators />}
                                />
                                <Route
                                  path="/coordinatorsDrivers"
                                  element={<Navigate to="/drivers" replace />}
                                />
                                <Route
                                  path="/drivers/create"
                                  element={
                                    <CreateDriver onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/drivers/edit/*"
                                  element={
                                    <EditDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/drivers/view/*"
                                  element={
                                    <ViewDriver
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route path="/drivers" element={<Drivers />} />
                                <Route
                                  path="/locations/create"
                                  element={
                                    <CreateLocation onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/locations/edit/*"
                                  element={
                                    <EditLocation
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/locations/view/*"
                                  element={
                                    <ViewLocation
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/locations"
                                  element={<Locations />}
                                />
                                <Route
                                  path="/users/create"
                                  element={<CreateUser onClose={handleClose} />}
                                />
                                <Route
                                  path="/users/edit/*"
                                  element={
                                    <EditUser
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/users/view/*"
                                  element={
                                    <ViewUser
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route path="/users" element={<Users />} />
                                <Route
                                  path="/customers/create"
                                  element={
                                    <CreateCustomer onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/customers/edit/*"
                                  element={
                                    <EditCustomer
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/customers/view/*"
                                  element={
                                    <ViewCustomer
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/customers"
                                  element={<Customers />}
                                />
                                <Route
                                  path="/facilities/create"
                                  element={
                                    <CreateFacility onClose={handleClose} />
                                  }
                                />
                                <Route
                                  path="/facilities/edit/*"
                                  element={
                                    <EditFacility
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/facilities/view/*"
                                  element={
                                    <ViewFacility
                                      id={pathname.split('/')[3]}
                                      onClose={handleClose}
                                    />
                                  }
                                />
                                <Route
                                  path="/facilities"
                                  element={<Facilities />}
                                />
                                <Route path="/pushs" element={<Pushs />} />
                                <Route path="/emails" element={<Emails />} />
                                <Route path="/" element={<StartPage />} />
                                <Route path="*" element={<NotFoundPage />} />
                              </Routes>
                            </React.Suspense>
                          </Layout>
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </ModalImgProvider>
              </ModalMapProvider>
            </ModalFormProvider>
          </SnackbarProvider>
        </LoaderProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
