import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import CloseIcon from '@mui/icons-material/Close';

import { stopPropagation } from '../utils/stopPropagation';
import { IconButton, InputAdornment } from '@mui/material';

export default function SelectFieldControl({
  fieldName,
  label,
  placeholder,
  items,
  disabledItems,
  disabled,
  formik,
  height,
  clearable,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly items: readonly (
    | string
    | { value: string | number | boolean; label: string }
  )[];
  readonly disabledItems?: readonly (string | number | boolean)[];
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
  readonly height?: string | number;
  readonly clearable?: boolean;
}) {
  const [statusOpen, setStatusOpen] = React.useState<boolean>(false);

  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  const handleStatusKeyUp = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    stopPropagation(event);
    handleStatusClose();
  };

  const handleStatusKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    stopPropagation(event);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };
  const handleStatusClose = () => {
    setStatusOpen(false);
    // handleStatusChange();
    queueMicrotask(() => formik.setFieldTouched(fieldName, true, true));
  };

  const handleClear = () => {
    formik.setFieldValue(fieldName, null);
  };

  return (
    <Box key={fieldName} sx={{ height: height || '75px' }}>
      <FormControl
        fullWidth
        size="small"
        disabled={disabled}
        error={formikTouched && Boolean(formik.errors[fieldName])}
      >
        <InputLabel
          id={fieldName}
          htmlFor={'input_id_' + fieldName}
        >{`${label}`}</InputLabel>
        <Select
          labelId={fieldName}
          id={fieldName}
          name={fieldName}
          label={label}
          inputProps={{ id: 'input_id_' + fieldName }}
          open={statusOpen}
          value={
            formikValue === undefined || formikValue === null
              ? ''
              : typeof formikValue === 'boolean'
                ? `${formikValue}`
                : formikValue
          }
          renderValue={(selected) => {
            if (placeholder && selected.length === 0) {
              return placeholder;
            }
            return selected;
          }}
          onChange={formik.handleChange}
          onOpen={handleStatusOpen}
          onClose={handleStatusClose}
          onKeyUpCapture={handleStatusKeyUp}
          onKeyDownCapture={handleStatusKeyDown}
          onBlur={formik.handleBlur}
          endAdornment={
            clearable && formikValue ? (
              <InputAdornment position="end" sx={{ marginRight: 1.5 }}>
                <IconButton onClick={handleClear} size="small">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        >
          {items.map((item) => (
            <MenuItem
              key={
                typeof item === 'string'
                  ? item
                  : typeof item.value === 'boolean'
                    ? `${item.value}`
                    : item.value
              }
              value={
                typeof item === 'string'
                  ? item
                  : typeof item.value === 'boolean'
                    ? `${item.value}`
                    : item.value
              }
              disabled={
                disabledItems &&
                disabledItems.includes(
                  typeof item === 'string' ? item : item.value,
                )
              }
            >
              {typeof item === 'string' ? item : `${item.label}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formikTouched &&
            formikError &&
            `${
              typeof formikError === 'string' ? formikError : 'Validation error'
            }`}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
