import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';

import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  fromCorrected,
  fromISOCorrected,
  toCorrected,
} from '../utils/dateTimeConverters';

export default function DateTimeFieldControl({
  fieldName,
  label,
  minDateTime,
  maxDateTime,
  disablePast,
  disableFuture,
  disabled,
  formik,
  height,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly minDateTime?: Date;
  readonly maxDateTime?: Date;
  readonly disablePast?: boolean;
  readonly disableFuture?: boolean;
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
  readonly height?: string | number;
}) {
  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  return (
    <Box key={fieldName} sx={{ height: height || '75px' }}>
      <DateTimePicker
        label={label}
        ampm={false}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        disablePast={disablePast}
        disableFuture={disableFuture}
        disabled={disabled}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            size: 'small',
            id: fieldName,
            name: fieldName,
            onBlur: (event) => {
              formik.handleBlur(event);
            },
            error: formikTouched && Boolean(formikError),
            helperText:
              formikTouched &&
              formikError &&
              `${
                typeof formikError === 'string'
                  ? formikError
                  : 'Validation error'
              }`,
          },
        }}
        value={
          typeof formikValue === 'string'
            ? fromISOCorrected(formikValue)
            : (formikValue && fromCorrected(formikValue)) || null
        }
        onChange={(value) =>
          formik.setFieldValue(
            fieldName,
            (value && toCorrected(value)) || null,
            true,
          )
        }
        onClose={() => {
          queueMicrotask(() => formik.setFieldTouched(fieldName, true, true));
        }}
      />
    </Box>
  );
}
